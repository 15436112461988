import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import * as userSession from 'Helpers/userSession';
import { getItems, loadNextPage } from 'Actions/items';
import updateUserSession from 'Actions/userSession';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';
import { fetchFiltersByCategoryId, isFetching } from 'Actions/filters';
import {
    itemsMetadataSelector,
    relevanceItemsSelector,
    isFetchingItemsSelector,
    getItemsUrl,
    getItemsParamsSelector,
    userSessionSelector,
    itemsPageIndexSelector,
    itemsSimilarAdsSelector,
    relevanceCurrentPageItemsSelector
} from 'Selectors/items';
import { APIVERSION, ITEMS_SEARCH_SOURCE } from 'Constants/items';
import { SHOW_DID_YOU_MEAN_HINT } from 'Constants/tracking';
import withTrack from 'HOCs/withTrack/withTrack';
import CookieManager from 'Helpers/cookies';
import { isEmpty } from 'Helpers/objects';
import { getAppliedFilterCategoryId } from 'Selectors/filters';
import withRouter from 'HOCs/withRouter';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';

const withItems = (itemsSource, facetLimit, retrySSRFailed, componentConfig = {}) => WrappedComponent => {
    const variantInfo = {
        disabled: false,
        variant: 'a'
    };

    class WithItems extends React.Component {
        componentDidMount() {
            const { userSessionLong, items, itemsMetadata, dispatch, params } = this.props;
            const sessionCookie = CookieManager.readCookie('onap');
            const sessionLongByCookie = (sessionCookie && userSession.getSessionLong(sessionCookie));

            if (!userSessionLong && sessionCookie) {
                dispatch(updateUserSession(sessionLongByCookie));
            }

            if (items && items.length && !isEmpty(itemsMetadata)) {
                this.trackListingRequest(true);
            }

            this.fetchFilters = Promise.resolve();

            if (componentConfig.shouldFetchFiltersFirst && params?.categoryID) {
                const isAutosPlatform = this.props.config.get('olxAutos', 'isMXCL');

                this.fetchFilters = dispatch(fetchFiltersByCategoryId(isAutosPlatform, params.categoryID, variantInfo));
            }

            // forcing a new call to the items API
            this.fetchItems(true);
        }

        componentDidUpdate(prevProps) {
            const {
                items,
                itemsMetadata,
                itemsPageIndex,
                itemsParams
            } = this.props;

            // Before checking if the location ID has been updated, ensure that the refetchAds configuration is set to true.
            //  If true, this will trigger getItems with the refetch parameter set to true. Consequently, the collection's cached value will be ignored, forcing a new call to the items API.
            const shouldFetch = componentConfig.refetchAds && prevProps.itemsParams?.location !== itemsParams?.location;

            this.fetchFilters.finally(() => this.fetchItems(shouldFetch));

            if (items && !isEmpty(itemsMetadata) && (prevProps.items !== items) && (prevProps.itemsMetadata !== itemsMetadata)) {
                this.trackListingRequest();
                if (itemsPageIndex === '0') {
                    this.trackDidYouMeanHint();
                }
            }
        }

        fetchItems = (refetch = false) => {
            const cookies = CookieManager.getAllCookies();
            const {
                location,
                dispatch,
                location: { query: { expired = false } = {}} = {},
                config,
                sessionFeatures
            } = this.props;

            if (!expired) {
                let apiVersion;
                let longFiltersCategoryList;

                if (itemsSource === ITEMS_SEARCH_SOURCE) {
                    apiVersion = APIVERSION.V4;
                    longFiltersCategoryList = config.get('longFiltersCategoryList');
                }

                dispatch(getItems({
                    itemsSource,
                    facetLimit,
                    cookies,
                    location,
                    apiVersion,
                    longFiltersCategoryList,
                    retrySSRFailed,
                    refetch,
                    sessionFeatures
                }));
            }
        }

        trackDidYouMeanHint = () => {
            const { itemsMetadata } = this.props;
            const { show_hint, original_term: search_user_query, suggested_term: search_string } = itemsMetadata;

            if (show_hint) {
                this.props.track(SHOW_DID_YOU_MEAN_HINT, {
                    search_string,
                    search_user_query
                });
            }
        }

        trackListingRequest = (onMounted = false) => {
            const { itemsPageIndex, location } = this.props;

            this.props.trackListingRequest(itemsPageIndex, onMounted, location);
        }

        handleOnLoadNextPage = () => {
            const { dispatch } = this.props;
            let apiVersion;

            if (itemsSource === ITEMS_SEARCH_SOURCE) {
                apiVersion = APIVERSION.V4;
            }

            dispatch(loadNextPage(itemsSource, apiVersion));
        };

        getRelevanceTrackInfo = (metadata = {}) => {
            const { feed_version = '-' } = metadata;

            return { feed_version };
        };

        parentItems = ({ modified_filters } = {}) => {
            if (modified_filters && modified_filters.location) {
                const { from: payloadFrom, to: payloadTo, payload } = modified_filters.location;
                const [firstLocationFrom] = payloadFrom;
                const [firstLocationTo] = payloadTo;
                const locationFrom = payload[firstLocationFrom]
                    ? payload[firstLocationFrom].name
                    : '';
                const locationTo = payload[firstLocationTo]
                    ? payload[firstLocationTo].name
                    : '';

                return {
                    locationFrom,
                    locationTo
                };
            }
            return undefined;
        }

        render() {
            const { itemsMetadata, itemsParams, similarAdsData } = this.props;
            const items = this.props.items || [];
            const itemsTrackInfo = this.getRelevanceTrackInfo(itemsMetadata);
            const parentItems = this.parentItems(itemsMetadata);

            return (
                <WrappedComponent
                    { ...this.props }
                    items={ items }
                    similarAdsData={ similarAdsData }
                    itemsParams={ itemsParams }
                    parentItems={ parentItems }
                    onLoadNextPage={ this.handleOnLoadNextPage }
                    trackListingRequest={ this.trackListingRequest }
                    itemsTrackInfo={ itemsTrackInfo }
                />
            );
        }
    }

    WithItems.propTypes = {
        items: PropTypes.array.isRequired,
        isFetchingItems: PropTypes.bool,
        itemsMetadata: PropTypes.object,
        similarAdsData: PropTypes.array,
        itemsParams: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        userSessionLong: PropTypes.string,
        track: PropTypes.func.isRequired,
        trackListingRequest: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        itemsPageIndex: PropTypes.string,
        config: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        variant: PropTypes.string,
        params: PropTypes.object,
        sessionFeatures: PropTypes.array.isRequired
    };

    WithItems.defaultProps = {
        itemsPageIndex: '0',
        similarAdsData: []
    };

    /**
     * Makes an items API call for the wrapped component
     */
    WithItems.fetchData = (...args) => {
        const [dispatch, renderProps, reqProps, { config }, store] = args;
        const { location: { query: { expired = false } = {}} = {}} = renderProps;

        if (!expired) {
            // if the wrapped component have its own 'fetchData'
            // call that first then proceed with fetching the items data
            const promises = [
                (WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())
            ];

            return Promise.all(promises).then(() => {
                const { sessionFeatures, cookies } = reqProps;

                const sessionLongByCookie = cookies
                    && userSession.getSessionLong(cookies.onap);
                const state = store.getState();
                const { location } = renderProps;
                let apiVersion;
                let longFiltersCategoryList;

                if (itemsSource === ITEMS_SEARCH_SOURCE) {
                    apiVersion = APIVERSION.V4;
                    longFiltersCategoryList = config.longFiltersCategoryList;
                }

                if (sessionLongByCookie && state.user && !state.user.sessionLong) {
                    dispatch(updateUserSession(sessionLongByCookie));
                }

                return dispatch(getItems({
                    itemsSource,
                    facetLimit,
                    cookies: reqProps.cookies,
                    location,
                    apiVersion,
                    longFiltersCategoryList,
                    sessionFeatures
                }));
            });
        }
        return Promise.resolve();
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithItems.displayName = `withItems(${wrappedComponentName})`;

    const isFetchingItems = isFetchingItemsSelector(itemsSource);
    const itemsParams = getItemsParamsSelector(itemsSource);

    const mapStateToProps = (state, ownProps) => ({
        items: relevanceItemsSelector(state, ownProps),
        currentPageItems: relevanceCurrentPageItemsSelector(state, ownProps),
        isFetchingItems: isFetchingItems(state) || (componentConfig.shouldFetchFiltersFirst && isFetching(state, ownProps.params?.categoryID, variantInfo)),
        itemsMetadata: itemsMetadataSelector(state),
        similarAdsData: itemsSimilarAdsSelector(state, ownProps),
        itemsUrl: getItemsUrl(itemsSource),
        itemsParams: itemsParams(state, { ...ownProps, facetLimit, sessionFeatures: ownProps.sessionFeatures || ['olxin-1117'] }),
        itemsPageIndex: itemsPageIndexSelector(state),
        userSessionLong: userSessionSelector(state),
        trackOrigin: state && state.track && state.track.origin,
        selectedCategoryId: getAppliedFilterCategoryId(state)
    });

    const connectedComponent = compose(
        withRouter,
        withConfig,
        withTrack,
        withSessionFeature,
        connect(mapStateToProps)
    )(WithItems);

    return hoistNonReactStatic(connectedComponent, WrappedComponent, { fetchData: true });
};

export default withItems;
