/* global window */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import map from 'lodash/map';
import withRouter from 'HOCs/withRouter';
import { FormattedMessage as Translation } from 'react-intl';
import Auth from '../../Auth';
import setLoginModalState from 'Actions/setLoginModalState';
import { resetFilters, setOriginSync, setBrowsingModeSync } from 'Actions/track';
import SEO from 'Components/SEO/SEO';
import AdvertisingComponent from 'Components/AdvertisingComponent/AdvertisingComponent';
import Page from 'Components/Page/Page';
import Footer from 'Components/Footer/Footer';
import SearchHeader from 'Components/HeaderWrapper/Headers/SearchHeader/SearchHeader.APP_TARGET';
import { ITEMS_FEED_SOURCE } from 'Constants/items';
import * as helmetHelper from 'Helpers/helmet';
import withItems from 'HOCs/withItems/withItems';
import withLocation from 'HOCs/withLocation/withLocation';
import { withConfig } from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import HomeContent from './components/HomeContent.APP_TARGET';
import css from './Home.APP_TARGET.scss';
import withCookies from 'HOCs/withCookies/withCookies';
import SubHeader from 'Components/SubHeader/SubHeader.APP_TARGET';
import { getNavigationTree } from 'Actions/navigationTree';
import { getBundles } from 'Actions/bundles';
import withDataFetching from 'HOCs/withDataFetching/withDataFetching';
import { isFetchingGps } from 'Selectors/location';
import withErrorBoundary from 'HOCs/withErrorBoundary/withErrorBoundary';
import { clearSurvey } from 'Actions/survey';
import { REQUEST_ORIGINS, REQUEST_ORIGIN } from 'olx-autos-landing-page';
import { ToastMessage } from 'panamera-react-ui';
import classNames from 'classnames';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { getIsStoreInspectedCar } from 'Helpers/item';
import { ConfigContext } from 'Components/ConfigProvider/ConfigContext';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { ComponentDependencyProvider, UserSession, EvaluationConstants, CampaingHelpers } from 'olx-autos-landing-page';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { withSchema } from 'HOCs/withSchema/withSchema';
import { getLoggedUserData, updateDealerDetails } from 'Actions/user';
import DealerNudge from '../../components/DealerNudge/DealerNudge.jsx';

const hasItemsUpdated = (currentItems = [], prevItems = []) => {
    const currentItemsIds = currentItems.map(item => item.id);
    const prevItemsIds = prevItems.map(item => item.id);

    if (currentItemsIds.length !== prevItemsIds.length) {
        return true;
    }
    currentItemsIds.sort();
    prevItemsIds.sort();
    return (!currentItemsIds.every((value, index) => value === prevItemsIds[index]));
};

const ComponentDependency = { ConfigContext, ReactObserver };

export class Home extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        isFetchingItems: PropTypes.bool,
        location: PropTypes.object,
        resetFiltersTrack: PropTypes.func,
        setOriginTrack: PropTypes.func,
        setBrowsingMode: PropTypes.func,
        setLoginModalState: PropTypes.func,
        router: PropTypes.shape({
            push: PropTypes.func
        }),
        geoLocationName: PropTypes.string,
        isFetchingGeoLocation: PropTypes.bool,
        onLoadNextPage: PropTypes.func,
        locationCountry: PropTypes.string,
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }),
        trackerOrigins: PropTypes.object,
        impressions: PropTypes.shape({
            add: PropTypes.func.isRequired
        }),
        track: PropTypes.func.isRequired,
        isFetchingGps: PropTypes.bool.isRequired,
        // laquesisShowSurvey: PropTypes.func,
        // clearSurvey: PropTypes.func,
        // surveyId: PropTypes.string,
        itemsMetadata: PropTypes.object,
        isPaidCampaign: PropTypes.bool,
        updateDealerDetails: PropTypes.func.isRequired,
        getUser: PropTypes.func.isRequired
    };

    static defaultProps = {
        items: [],
        location: {},
        resetFiltersTrack: () => { },
        setOriginTrack: () => { },
        setBrowsingMode: () => { },
        trackFilters: {},
        trackFiltersMetadata: {},
        trackOrigin: '',
        onLoadNextPage: () => { },
        itemsTrackInfo: {},
        laquesisShowSurvey: () => { },
        clearSurvey: () => { },
        surveyId: '',
        itemsMetadata: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            checkedImpressions: false,
            adIdsToUpdate: [],
            offline: false,
            showOnlineMessage: false,
            showDealerPopup: true
        };
        this.timer = null;
        this.siteCode = props.marketConfig.get('siteCode');
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            this.addEventListeners();
        }

        const { resetFiltersTrack, setOriginTrack, setBrowsingMode } = this.props;

        if (window.navigator && window.navigator.onLine === false) {
            this.offlineHandler();
        }

        resetFiltersTrack();
        setOriginTrack(this.props.trackerOrigins.HOME);
        setBrowsingMode(this.props.trackerOrigins.HOME);
        // const { surveyId } = this.props;
        // if (window !== undefined && surveyId !== '') {
        //     if (window.isSurveyAvailable) {
        //         this.checkForSurvey();
        //     }
        //     else {
        //         window.laquesisFunctionsCallback = () => {
        //             this.checkForSurvey();
        //         };
        //     }
        // }

        setSessionStorageItem(REQUEST_ORIGIN, REQUEST_ORIGINS.HOME_PAGE);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.geoLocationName !== nextProps.geoLocationName) {
            return true;
        }

        if (this.props.isFetchingGeoLocation !== nextProps.isFetchingGeoLocation) {
            return true;
        }

        if (this.props.isFetchingItems !== nextProps.isFetchingItems) {
            return true;
        }

        if (this.props.isFetchingGps !== nextProps.isFetchingGps) {
            return true;
        }

        if (this.state.offline !== nextState.offline) {
            return true;
        }

        if (this.state.showOnlineMessage !== nextState.showOnlineMessage) {
            return true;
        }
        if (this.state.showDealerPopup !== nextState.showDealerPopup) {
            return true;
        }

        const currentItems = this.props.items;
        const nextItems = nextProps.items;

        if (hasItemsUpdated(currentItems, nextItems)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const shouldTrackNewItems = hasItemsUpdated(this.props.items, prevProps.items);

        if (shouldTrackNewItems) {
            this.setAdIdsToUpdate();
        }
    }

    componentWillUnmount() {
        const { setOriginTrack } = this.props;

        setOriginTrack(this.props.trackerOrigins.HOME);
        if (typeof window !== 'undefined') {
            window.removeEventListener('online', this.onlineHandler, false);
            window.removeEventListener('offline', this.offlineHandler, false);
        }
    }

    // checkForSurvey() {
    //     const { laquesisShowSurvey, clearSurvey, surveyId } = this.props;

    //     if (surveyId !== '' && (window !== undefined && window.isSurveyAvailable(surveyId))) {
    //         laquesisShowSurvey(surveyId);
    //     }
    //     clearSurvey();
    // }

    loadNextPage = () => {
        const { items } = this.props;

        this.setAdIdsToUpdate(['baxter-ads-home-bottom']);

        if (items) {
            this.props.onLoadNextPage();
        }
    };

    addEventListeners() {
        window.addEventListener('online', this.onlineHandler, false);
        window.addEventListener('offline', this.offlineHandler, false);
    }

    offlineHandler = () => {
        const offlineTimeout = 5000;

        this.timer = setTimeout(() => {
            this.setState({ offline: true });
            document.body.style.pointerEvents = 'none';
        }, offlineTimeout);
    }

    onlineHandler = () => {
        document.body.style.pointerEvents = 'auto';
        clearTimeout(this.timer);
        this.setState({
            offline: false,
            showOnlineMessage: true
        });
    }

    clearMessage = () => {
        this.setState({
            showOnlineMessage: false
        });
    }

    getOnlineMessageToast = () => {
        return (
            <ToastMessage
                expiration={ 5000 }
                onExpired={ this.clearMessage }
            >
                <div className={ classNames(css.banner, css.onlineBanner) } >
                    <Icon icon="online" color="white" size={ 40 } />
                    <span className={ css.onlineText }><Translation id="onlineBannerText" defaultMessage="You are back online" /></span>
                </div>
            </ToastMessage>
        );
    }

    setAdIdsToUpdate = (ids = []) => {
        this.setState({ adIdsToUpdate: ids });
    };

    onClickPost = e => {
        e.preventDefault();

        const origin = this.props.trackerOrigins.LISTING;

        this.props.setOriginTrack(origin);
        this.track('posting_tap_post', {
            origin,
            select_from: 'sell_button'
        });

        if (Auth.isLoggedIn()) {
            this.props.router.push({
                pathname: '/post'
            });
        }
        else {
            this.props.setLoginModalState({
                state: true,
                redirectTo: '/post',
                trackProps: { origin: 'posting' }
            });
        }
    };

    onViewItem = impression => {
        const { checkedImpressions } = this.state;
        const items = this.props.items || [];

        const itemsIds = map(items, 'id');
        const position = itemsIds.indexOf(impression.id);

        if (!checkedImpressions && position > 0) {
            const itemsToBeAdded = items.slice(0, position);

            itemsToBeAdded.forEach(item => {
                const { id, title, user_id, spell, inspection_info } = item;
                const isFeatured = !!(item.package && (item.package.id || item.package.name));
                const { id: spell_id = 0 } = spell || {};
                const isInspected = !!getIsStoreInspectedCar(inspection_info);

                this.props.impressions.add({ id, title, user_id, isFeatured, spell_id, isInspected }, true);
            });

            this.setState({
                checkedImpressions: true
            });
        }

        this.props.impressions.add(impression);
    };

    track = (event, props) => this.props.track(event, props);

    getHelmet(items) {
        const { pathname, search, query } = this.props.location;
        const defaultPageSize = 20;
        const path = pathname || '';
        const searchParams = search ? path + search : '';
        const searchPath = searchParams.replace(/[?&]page=[0-9]+/, '');
        const totalPages = items ? Math.ceil(items.total / defaultPageSize) : null;
        const currentPage = this.props.location && query && query.page;
        const hostUrl = this.props.marketConfig.get('host');
        const basename = this.props.location.basename ? this.props.location.basename.toLowerCase() : '';
        const page = currentPage ? `/?page=${currentPage}` : '';
        const linkCanonical = [
            { rel: 'canonical', href: hostUrl + basename + page }
        ];
        const links = helmetHelper.getLinks(currentPage, hostUrl, path, totalPages, searchParams, searchPath, items.nextPageUrl);

        return (
            <React.Fragment>
                <Helmet link={ linkCanonical } />
                <Helmet link={ links.linkPrevPage } />
                <Helmet link={ links.linkNextPage } />
            </React.Fragment>
        );
    }

    renderBanners = (slot, adIdsToUpdate = []) => {
        let dynamicAdId = '';
        const slotToDynamicAdIdMap = {
            top: 'home_0',
            bottom: 'home_default'
        };

        dynamicAdId = slotToDynamicAdIdMap[slot] || 'home_default';
        return (
            <AdvertisingComponent
                slot={ slot }
                page="home"
                adIdsToUpdate={ adIdsToUpdate }
                dynamicAdId={ dynamicAdId }
            />
        );
    }

    subHeader = <SubHeader showBackToTop />;
    feedsTitle = (
        <div className={ css.feedsTitle }>
            <Translation id="freshRecommendations" />
        </div>
    );

    handleIconClick = () => {
        const { EVALUATION_STATES: { VALUATION_STARTED }} = EvaluationConstants;
        const redirectRoute = this.props.isPaidCampaign ? this.props.marketConfig.get('landingPageRoutes', 'sell-ppc') : this.props.marketConfig.get('landingPageRoutes', 'sell-organic');

        UserSession.setUserEvaluationState(VALUATION_STARTED);
        // eslint-disable-next-line react/prop-types
        this.props.router.replace(`${redirectRoute}`);
    }

    handleUpdateShowroomClick = () => {
        const params = {
            data: {
                showroom_status: 'edited'
            }
        };

        this.props.updateDealerDetails(params).then(res => {
            if (res.ok) {
                this.props.getUser(true);
                this.props.router.push('/editDealerProfile');
            }
        });
    }

    handleSkipShowroomClick = () => {
        const params = {
            data: {
                showroom: {
                    last_skipped_at: Date.now()
                }
            }
        };

        this.props.updateDealerDetails(params).then(res => {
            if (res.ok) {
                this.setState({
                    showDealerPopup: false
                });
            }
        });
    }

    render() {
        const { items, isFetchingItems, locationCountry, marketConfig, itemsMetadata } = this.props;
        const extraTrackAttr = { feed_version: itemsMetadata.feed_version };
        const { offline, showOnlineMessage } = this.state;
        const enabledRealImpressions = marketConfig.get('realImpressions').enabled;
        const footer = (
            <Footer enableTopMobile showDownloadApp={ marketConfig.get('downloadApp').enabled } showMgid={ marketConfig.get('mgid').enabled } />
        );
        const isFetchingItemsResult = isFetchingItems;
        const itemsResult = items;
        const pageHeader = (
            <SearchHeader enableLocationMenu offline={ offline } />
        );

        return (
            <ComponentDependencyProvider value={ ComponentDependency }>
                <Page header={ pageHeader } scrollId="Home" subHeader={ this.subHeader } footer={ footer } offline={ offline } >
                    {this.state.showDealerPopup
                         && <DealerNudge
                             handleUpdateShowroomClick={ this.handleUpdateShowroomClick }
                             handleSkipShowroomClick={ this.handleSkipShowroomClick } />
                    }

                    {this.getHelmet(items)}
                    <SEO page="home" />
                    { this.renderBanners('andbeyond') }
                    <HomeContent
                        items={ itemsResult }
                        isFetchingItems={ isFetchingItemsResult }
                        onViewItem={ this.onViewItem }
                        location={ this.props.location }
                        locationCountry={ locationCountry }
                        enabledRealImpressions={ enabledRealImpressions }
                        onLoadNextPage={ this.loadNextPage }
                        renderBanners={ this.renderBanners }
                        adIdsToUpdate={ this.state.adIdsToUpdate }
                        feedsTitle={ this.feedsTitle }
                        onClickPost={ this.onClickPost }
                        showLoadMore={ !!itemsResult.nextPageUrl }
                        offline={ offline }
                        extraTrackAttr={ extraTrackAttr }
                        handleIconClick={ this.handleIconClick }
                    />

                </Page>
                {offline
                    && <div className={ classNames(css.banner, css.offlineBanner) } >
                        <Icon icon="offline" color="white" size={ 40 } />
                        <Translation id="offlineBannerText" defaultMessage="You seem to be offline" />
                    </div>}
                {showOnlineMessage && this.getOnlineMessageToast()}
            </ComponentDependencyProvider>
        );
    }
}

const actionCreator = (dispatch, reqProps, { isSSR } = {}) => {
    const promises = [dispatch(getNavigationTree(true))];

    if (!isSSR) {
        promises.push(
            dispatch(getBundles(reqProps))
        );
    }
    return Promise.all(promises);
};

export const mapStateToProps = (state, ownProps) => ({
    trackFilters: state.track.filters,
    trackFiltersMetadata: state.track.filtersMetadata,
    trackOrigin: state.track.origin,
    isFetchingGps: isFetchingGps(state),
    surveyId: state.survey && state.survey.surveyId,
    isPaidCampaign: CampaingHelpers.isPaidCampaign(ownProps?.location?.query, ownProps?.marketConfig?.get())
});

export const mapDispatchToProps = dispatch => ({
    resetFiltersTrack: () => dispatch(resetFilters()),
    setOriginTrack: origin => dispatch(setOriginSync(origin)),
    setBrowsingMode: mode => dispatch(setBrowsingModeSync(mode)),
    setLoginModalState: state => dispatch(setLoginModalState(state)),
    clearSurvey: () => dispatch(clearSurvey()),
    updateDealerDetails: params => dispatch(updateDealerDetails(params)),
    getUser: (forceFetch, callback) => dispatch(getLoggedUserData(forceFetch)).then(res => {
        if (callback) {
            return callback(res);
        }
        return null;
    })
});

const connectedComponent = compose(
    withItems(ITEMS_FEED_SOURCE, undefined, true),
    withLocation,
    withRouter,
    withConfig('marketConfig'),
    withSchema('WebSite'),
    connect(mapStateToProps, mapDispatchToProps),
    withTrack,
    withDataFetching(actionCreator),
    withCookies,
    withErrorBoundary()
    // ,withSurvey
)(Home);

export default connectedComponent;
